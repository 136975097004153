import Layout from '@/components/layout/Layout';
import '@/styles/globals.css';
import '@/styles/styles.css';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AppUserProvider } from '../contexts/AppUserContext';
import { Provider } from 'react-redux';
import store from '@/redux/store';

export default function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    // Intercom code snippet
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/wym4303y';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();

    window.Intercom('boot', {
      app_id: 'wym4303y',
    });
  }, []);

  useEffect(() => {
    if (
      router.pathname === '/apps/[appId]' ||
      router.pathname === '/embed/[appId]'
    ) {
      window?.Intercom(
        'shutdown'
        // , {
        //   hide_default_launcher: true,
        // }
      );
    } else {
      if (window?.Intercom) {
        window?.Intercom('boot', {
          app_id: 'wym4303y',
        });
        // window?.Intercom('hide')
      }
    }
  }, [router?.pathname]);

  return (
    <>
      <UserProvider>
        <AppUserProvider>
          <Provider store={store}>
            {router.pathname === '/embed/[appId]' ? (
              <Component {...pageProps} />
            ) : (
              <Layout>
                <Component {...pageProps} />
              </Layout>
            )}
          </Provider>
        </AppUserProvider>
      </UserProvider>
    </>
  );
}
