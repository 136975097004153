import * as Sentry from '@sentry/nextjs';

export const generateBackendCompatibleSchema = (
  appFields,
  outputVariablesList
) => {
  const backendSchema = {
    schema: { required_variables: {}, optional_variables: {}, model: {} },
  };

  try {
    // Generate the required_variables and optional_variables in this block!
    for (const field of appFields.children) {
      if (
        field.element === 'InputWithLabel' ||
        field.element === 'TextAreaWithLabel' ||
        field.element === 'RadioButtonGroup' ||
        field.element === 'CheckboxGroup'
      ) {
        if (field.props.required) {
          backendSchema.schema.required_variables[field.props.name] = {
            type: 'string',
          };
        } else {
          backendSchema.schema.optional_variables[field.props.name] = {
            type: 'string',
            default: '',
            value: '${input}',
          };
        }

        // TODO: Each optional field must have a `default` field and a `value` field!
        // These will be required to ensure the BE is able to process the data correctly!
        // This check should come in the FE Schema validator which will be created later!
      }
    }

    // TODO: We will add more robust support to have multiple outputs in the required_variables to support a sequential prompt!
    if (!outputVariablesList)
      backendSchema.schema.required_variables['output'] = { type: 'string' };
    else {
      for (let i = 0; i < outputVariablesList.length; i++) {
        const opVariable = outputVariablesList[i];
        backendSchema.schema.required_variables[opVariable.name] = {
          type: 'string',
        };
      }
    }
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred in schemaHelpers.js at Line: 52',
      level: 'error',
      extra: JSON.stringify(err),
    });
  }

  return backendSchema;
};

export const generateUserRequestObject = (formInputs, appSchema) => {
  const requestObject = {
    schema_id: appSchema.id,
    required_variables: {},
    optional_variables: {},
  };

  try {
    // Generate the required_variables and optional_variables in this block!
    const extractedAppSchema =
      appSchema?.frontend_schema?.rendererCompatibleSchema;

    let appFields = null;
    if (extractedAppSchema.children.length > 1)
      appFields = extractedAppSchema.children;
    else appFields = extractedAppSchema.children[0].children;

    for (const field of appFields) {
      if (
        field.element === 'InputWithLabel' ||
        field.element === 'TextAreaWithLabel' ||
        field.element === 'RadioButtonGroup' ||
        field.element === 'CheckboxGroup'
      ) {
        const fieldName = field.props.name;

        if (field.props.required) {
          requestObject.required_variables[fieldName] =
            formInputs[fieldName] instanceof Array
              ? formInputs[fieldName].join(', ')
              : formInputs[fieldName];
        } else {
          requestObject.optional_variables[fieldName] =
            formInputs[fieldName] instanceof Array
              ? formInputs[fieldName].join(', ')
              : formInputs[fieldName];
        }

        // TODO: Each optional field must have a `default` field and a `value` field!
        // These will be required to ensure the BE is able to process the data correctly!
        // This check should come in the FE Schema validator which will be created later!

        //   if (!hasRequiredFlag) {
        //     requestObject.optional_variables[field.name] = formInputs[field.name]
        //   }
      }
    }

    return requestObject;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred in schemaHelpers.js at Line: 115',
      level: 'error',
      extra: JSON.stringify(err),
    });
  }
};

// export const validateSchema = async (backendSchema) => {}
