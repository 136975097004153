import { createContext, useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import { useUser as useAuth0User } from '@auth0/nextjs-auth0/client';
import axios from 'axios';

const AppUserContext = createContext();

const fetcher = async (url) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200 || response.status === 304) {
      const data = { ...response.data };

      const quotaResponse = await axios.get(
        `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/public_user/${response.data.id}`
      );

      data['remaining_app_executions'] =
        quotaResponse.data.remaining_app_executions;
      return data;
    }
  } catch (err) {
    throw new Error(err.response.data.error.code);
  }

  //   return null
};

export const AppUserProvider = ({ children }) => {
  const [appUser, setAppUser] = useState(null);

  const {
    user: auth0User,
    error: auth0Error,
    isLoading: auth0Loading,
  } = useAuth0User();
  const { data, error, mutate } = useSWR(
    auth0User ? '/api/auth/fetchUserData' : null,
    fetcher,
    {
      refreshInterval: 120000, // Fetch data every 120 seconds
      dedupingInterval: 100000, // Deduplicate requests within 100 seconds
    }
  );

  useEffect(() => {
    if (data) {
      setAppUser(data);
    }
  }, [data]);

  const value = {
    appUser,
    auth0User,
    isLoading: auth0Loading || (!error && !data),
    error: auth0Error || error,
    mutateUserData: mutate,
  };

  return (
    <AppUserContext.Provider value={value}>{children}</AppUserContext.Provider>
  );
};

export const useAppUser = () => {
  const context = useContext(AppUserContext);
  if (context === undefined) {
    throw new Error('useAppUser must be used within an AppUserProvider');
  }
  return context;
};
