import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import {
  fetchAvailableDocuments,
  fetchAvailableIndexes,
  fetchUserApps,
} from '@/helpers/editorHelpers';

export const fetchAllData = createAsyncThunk(
  'app/fetchAllData',
  async (userId) => {
    const availableDataFiles = await fetchAvailableDocuments();
    const availableIndexes = await fetchAvailableIndexes();
    const userApps = await fetchUserApps(userId);

    return {
      availableDataFiles,
      availableIndexes,
      userApps,
    };
  }
);

const initialState = {
  dataFiles: [],
  indexes: [],
  userApps: [],
  isLoadingAppData: true,
};

const commonDataSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.dataFiles = action.payload.availableDataFiles;
        state.indexes = action.payload.availableIndexes;
        state.userApps = action.payload.userApps;
        state.isLoadingAppData = false;
      })
      .addCase(fetchAllData.pending, (state) => {
        state.isLoadingAppData = true;
      })
      .addCase(fetchAllData.rejected, (state) => {
        state.isLoadingAppData = false;
      });
  },
});

export const selectDataFiles = (state) => state.commonData.dataFiles;
export const selectIndexes = (state) => state.commonData.indexes;
export const selectUserApps = (state) => state.commonData.userApps;
export const selectIsLoadingAppData = (state) =>
  state.commonData.isLoadingAppData;

export default commonDataSlice.reducer;
