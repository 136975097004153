export function transformer(jsonObject, rootTag) {
  const root = jsonObject[rootTag];
  const children = [];
  for (const key of root.nodes) {
    const processedNode = transformer(jsonObject, key);
    children.push(processedNode);
  }

  return {
    element: jsonObject[rootTag].type.resolvedName,
    displayName: jsonObject[rootTag].displayName,
    children: children,
    props: jsonObject[rootTag].props,
    isLeaf: children.length === 0,
    id: rootTag,
  };
}

// TODO: Need to improve this further to support multi-level object processing
export function getVariablesList(formSchema) {}

export function getVariablesListForPrompt(json) {
  const identifiedInputs = [];

  for (const field of json.children) {
    if (
      field.element === 'InputWithLabel' ||
      field.element === 'TextAreaWithLabel' ||
      field.element === 'RadioButtonGroup' ||
      field.element === 'CheckboxGroup'
    ) {
      const obj = {
        id: field.id,
        label: field.props.label,
        name: field.props.name || field.id,
        category: field.props.required
          ? 'required_variables'
          : 'optional_variables',
      };
      identifiedInputs.push(obj);
    }
  }

  return identifiedInputs;
}
