import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { defaultPromptStepObject } from '@/helpers/editorHelpers';
import * as Sentry from '@sentry/nextjs';

export const fetchChatbotDataById = createAsyncThunk(
  'app/fetchChatbotDataById',
  async ({ appId, userId }) => {
    try {
      const response = await axios.get(
        `/api/creator/fetchAppData?app_id=${appId}&user_id=${userId}`
      );

      if (response.status === 200) {
        return response.data.data[0];
      }
      throw new Error('Error fetching app data');
    } catch (err) {
      // Add Better Sentry Logging
      Sentry.captureEvent({
        message: 'An error occurred',
        level: 'error',
        extra: {
          ...err,
        },
      });
    }
  }
);

const initialState = {
  appData: {
    activeAppId: null,
    appDescription: '',
    appName: '',
    appStatus: 'DRAFT',
    appType: 'chatbot',
    backingId: null,
    backingIndexTags: '',
    botImageFile: null,
    botImagePreview: null,
    botImageURL:
      'https://dev-creator.rubber.ai/assets/icons/defaultBotIcon.svg',
    copyable: false,
    ctaTitle: '',
    ctaURL: '',
    customPath: null,
    draftAppData: null,
    files: [],
    fuseDocuments: true,
    indexName: '',
    ogImageFile: null,
    ogImageURL: '',
    modelPrompt: '',
    promptInputByCreator: '',
    selectedIndex: null,
    selectedModel: 'gpt-3.5-turbo',
    selectedDataFiles: [],
    showcase: false,
    showCTA: false,
    styles: {
      botMessageBackgroundColor: '#ffffff',
      botMessageFontColor: '#000000',

      chatBodyBackgroundColor: '#F2F2F2',

      headerBackgroundColor: '#ffffff',
      headerFontColor: '#000000',
      headerFontSize: '',
      headerFontFamily: '',
      pageBackgroundColor: '#ffffff',

      userMessageBackgroundColor: '#ffffff',
      userMessageFontColor: '#000000',
    },
    temperature: 0.5,
    topK: 1,
    welcomeMessage: 'Hi there! I am bot. How can I help you today?',
  },

  meta: {
    isPublishing: false,
    isSaving: false,
    showSocialShareModal: false,
    isLoadingAppData: true,
  },
};

const chatbotAppSlice = createSlice({
  name: 'chatbotApp',
  initialState,
  reducers: {
    updateChatbotActiveAppId: (state, action) => {
      state.appData.activeAppId = action.payload;
    },
    updateChatbotSelectedModel: (state, action) => {
      state.appData.selectedModel = action.payload;
    },
    updateChatbotBackingId: (state, action) => {
      state.appData.backingId = action.payload;
    },
    updateChatbotBackingIndexTags: (state, action) => {
      state.appData.backingIndexTags = action.payload;
    },
    updateChatbotBotImageFile: (state, action) => {
      state.appData.botImageFile = action.payload;
    },
    updateChatbotBotImagePreview: (state, action) => {
      state.appData.botImagePreview = action.payload;
    },
    updateChatbotBotImageURL: (state, action) => {
      state.appData.botImageURL = action.payload;
    },
    updateChatbotAppName: (state, action) => {
      state.appData.appName = action.payload;
    },
    updateChatbotAppDescription: (state, action) => {
      state.appData.appDescription = action.payload;
    },
    updateChatbotCtaTitle: (state, action) => {
      state.appData.ctaTitle = action.payload;
    },
    updateChatbotCtaURL: (state, action) => {
      state.appData.ctaURL = action.payload;
    },
    updateChatbotCustomPath: (state, action) => {
      state.appData.customPath = action.payload;
    },
    updateChatbotAppStatus: (state, action) => {
      state.appData.appStatus = action.payload;
    },
    updateChatbotAppType: (state, action) => {
      state.appData.appType = action.payload;
    },
    updateChatbotSelectedIndex: (state, action) => {
      state.appData.selectedIndex = action.payload;
    },
    updateChatbotSelectedDataFiles: (state, action) => {
      state.appData.selectedDataFiles = action.payload;
    },
    updateChatbotFiles: (state, action) => {
      state.appData.files = [...state.appData.files, ...action.payload];
    },
    clearChatbotFiles: (state) => {
      state.appData.files = [];
    },
    deleteChatbotFile: (state, action) => {
      const newList = [...state.appData.files];
      newList.splice(action.payload, 1);
      state.appData.files = newList;
    },
    updateChatbotFuseDocuments: (state, action) => {
      state.appData.fuseDocuments = action.payload;
    },
    updateChatbotOgImageFile: (state, action) => {
      state.appData.ogImageFile = action.payload;
    },
    updateChatbotOgImageURL: (state, action) => {
      state.appData.ogImageURL = action.payload;
    },
    updateChatbotPromptInputByCreator: (state, action) => {
      state.appData.promptInputByCreator = action.payload;
    },
    updateChatbotIndexName: (state, action) => {
      state.appData.indexName = action.payload;
    },

    // Style attributes
    updateChatbotHeaderBackgroundColor: (state, action) => {
      state.appData.styles.headerBackgroundColor = action.payload;
    },
    updateChatbotHeaderFontColor: (state, action) => {
      state.appData.styles.headerFontColor = action.payload;
    },
    updateChatbotChatBodyBackgroundColor: (state, action) => {
      state.appData.styles.chatBodyBackgroundColor = action.payload;
    },
    updateChatbotPageBackgroundColor: (state, action) => {
      state.appData.styles.pageBackgroundColor = action.payload;
    },
    updateChatbotBotMessageBackgroundColor: (state, action) => {
      state.appData.styles.botMessageBackgroundColor = action.payload;
    },
    updateChatbotBotMessageFontColor: (state, action) => {
      state.appData.styles.botMessageFontColor = action.payload;
    },
    updateChatbotUserMessageBackgroundColor: (state, action) => {
      state.appData.styles.userMessageBackgroundColor = action.payload;
    },
    updateChatbotUserMessageFontColor: (state, action) => {
      state.appData.styles.userMessageFontColor = action.payload;
    },
    copyChatbotAppData: (state, action) => {
      const app = action.payload;
      let temperature =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
          ?.temperature === undefined ||
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
          ?.temperature === null
          ? 0.5
          : app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
              ?.temperature;

      let selectedModel = null;
      const droppedModels = new Set([
        'text-ada-001',
        'text-babbage-001',
        'text-curie-001',
        'text-davinci-003',
      ]);

      selectedModel = app?.workflow_schema?.schema?.model?.nodes?.[0]
        ?.transformer
        ? droppedModels.has(
            app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
          )
          ? 'gpt-3.5-turbo'
          : app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
        : null;

      state.appData.activeAppId = null;
      state.appData.appDescription = app.description;
      state.appData.appName = app.title;
      state.appData.appStatus = 'DRAFT';
      state.appData.backingId = null;
      // app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_id;
      state.appData.backingIndexTags =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_tags.join(
          ', '
        ) || '';
      state.appData.promptInputByCreator =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.input;
      state.appData.botImageURL = app?.frontend_schema?.botImageURL;
      state.appData.copyable = app.copyable;
      state.appData.customPath = null;
      state.appData.draftAppData = app;
      state.appData.ogImageURL = app.frontend_schema.ogImageURL || null;
      state.appData.modelPrompt = '';
      state.appData.selectedModel = selectedModel || 'gpt-3.5-turbo';
      state.appData.showcase = app.showcase;
      state.appData.styles = app.frontend_schema.styles;
      state.appData.temperature = temperature;
      state.appData.topK =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.similarity_top_k || 1;
      state.appData.welcomeMessage = app.frontend_schema.welcomeMessage;

      state.meta.isLoadingAppData = false;
    },

    updateChatbotPublishingFlag: (state, action) => {
      state.meta.isPublishing = action.payload;
    },
    updateChatbotSavingFlag: (state, action) => {
      state.meta.isSaving = action.payload;
    },
    updateChatbotShowCTA: (state, action) => {
      state.appData.showCTA = action.payload;
    },
    updateChatbotTemperature: (state, action) => {
      state.appData.temperature = action.payload[0];
    },
    updateChatbotTopK: (state, action) => {
      state.appData.topK = action.payload[0];
    },
    updateChatbotWelcomeMessage: (state, action) => {
      state.appData.welcomeMessage = action.payload;
    },

    updateChatbotAppData: (state, action) => {
      const app = action.payload;

      let temperature =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
          ?.temperature === undefined ||
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
          ?.temperature === null
          ? 0.5
          : app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
              ?.temperature;

      let selectedModel = null;
      const droppedModels = new Set([
        'text-ada-001',
        'text-babbage-001',
        'text-curie-001',
        'text-davinci-003',
      ]);

      selectedModel = app?.workflow_schema?.schema?.model?.nodes?.[0]
        ?.transformer
        ? droppedModels.has(
            app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
          )
          ? 'gpt-3.5-turbo'
          : app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
        : null;

      state.appData.activeAppId = app.id;
      state.appData.appDescription = app.description;
      state.appData.appName = app.title;
      state.appData.appStatus = app.publishing_state;
      state.appData.backingId =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_id;
      state.appData.backingIndexTags =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_tags.join(
          ', '
        ) || '';
      state.appData.promptInputByCreator =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.input;
      state.appData.botImageURL = app?.frontend_schema?.botImageURL;

      state.appData.copyable = app.copyable;
      state.appData.customPath = null;
      state.appData.draftAppData = app;
      state.appData.fuseDocuments =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.fuse_documents || true;
      state.appData.ogImageURL = 'app';
      state.appData.modelPrompt = '';
      state.appData.selectedModel = selectedModel || 'gpt-3.5-turbo';
      state.appData.showcase = app.showcase;
      state.appData.showCTA = app.frontend_schema.showCTA;
      state.appData.ctaTitle = app.frontend_schema.ctaTitle;
      state.appData.ctaURL = app.frontend_schema.ctaURL;
      state.appData.styles = app.frontend_schema.styles;
      state.appData.temperature = temperature;
      state.appData.topK =
        app?.workflow_schema?.schema?.model?.nodes?.[0]?.similarity_top_k || 1;
      state.appData.welcomeMessage = app.frontend_schema.welcomeMessage;
    },

    resetChatbotAppData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatbotDataById.pending, (state) => {
        state.meta.isLoadingAppData = true;
      })
      .addCase(fetchChatbotDataById.rejected, (state) => {
        state.meta.isLoadingAppData = false;
      })
      .addCase(fetchChatbotDataById.fulfilled, (state, action) => {
        const app = action.payload;
        let temperature =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
            ?.temperature === undefined ||
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
            ?.temperature === null
            ? 0.5
            : app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer_args
                ?.temperature;

        let selectedModel = null;
        const droppedModels = new Set([
          'text-ada-001',
          'text-babbage-001',
          'text-curie-001',
          'text-davinci-003',
        ]);

        selectedModel = app?.workflow_schema?.schema?.model?.nodes?.[0]
          ?.transformer
          ? droppedModels.has(
              app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
            )
            ? 'gpt-3.5-turbo'
            : app?.workflow_schema?.schema.model?.nodes?.[0]?.transformer
          : null;

        state.appData.activeAppId = app.id;
        state.appData.appDescription = app.description;
        state.appData.appName = app.title;
        state.appData.appStatus = app.publishing_state;
        state.appData.backingId =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_id;
        state.appData.backingIndexTags =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_tags.join(
            ', '
          ) || '';
        state.appData.promptInputByCreator =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.input;
        state.appData.botImageURL = app?.frontend_schema?.botImageURL;
        state.appData.copyable = app.copyable;
        state.appData.customPath = null;
        state.appData.draftAppData = app;
        state.appData.fuseDocuments =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.fuse_documents ||
          true;
        state.appData.ogImageURL = app.frontend_schema.ogImageURL || null;
        state.appData.modelPrompt = '';
        state.appData.selectedModel = selectedModel || 'gpt-3.5-turbo';
        state.appData.showcase = app.showcase;
        state.appData.showCTA = app.frontend_schema.showCTA;
        state.appData.ctaTitle = app.frontend_schema.ctaTitle;
        state.appData.ctaURL = app.frontend_schema.ctaURL;
        state.appData.styles = app.frontend_schema.styles;
        state.appData.temperature = temperature;
        state.appData.topK =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.similarity_top_k ||
          1;
        state.appData.welcomeMessage = app.frontend_schema.welcomeMessage;

        state.meta.isLoadingAppData = false;
      });
  },
});

export const {
  updateChatbotActiveAppId,
  copyChatbotAppData,
  updateChatbotDraftAppData,
  updateChatbotModelPrompt,
  updateChatbotSelectedModel,
  updateChatbotBackingId,
  updateChatbotBackingIndexTags,
  updateChatbotBotImageFile,
  updateChatbotBotImageURL,
  updateChatbotAppName,
  updateChatbotAppDescription,
  updateChatbotCustomPath,
  updateChatbotAppStatus,
  updateChatbotAppType,
  updateChatbotSelectedIndex,
  updateChatbotShowCTA,
  updateChatbotCtaTitle,
  updateChatbotCtaURL,
  updateChatbotSelectedDataFiles,
  updateChatbotFiles,
  clearChatbotFiles,
  deleteChatbotFile,
  updateChatbotFuseDocuments,
  updateChatbotOgImageFile,
  updateChatbotBotImagePreview,
  updateChatbotOgImageURL,
  updateChatbotIndexName,
  updateChatbotPromptInputByCreator,

  updateChatbotHeaderBackgroundColor,
  updateChatbotHeaderFontColor,
  updateChatbotChatBodyBackgroundColor,
  updateChatbotPageBackgroundColor,
  // updateChatbotHeaderFontSize,
  // updateChatbotHeaderFontFamily,
  updateChatbotBotMessageBackgroundColor,
  updateChatbotBotMessageFontColor,
  updateChatbotUserMessageBackgroundColor,
  updateChatbotUserMessageFontColor,

  updateChatbotPublishingFlag,
  updateChatbotSavingFlag,
  updateChatbotTemperature,
  updateChatbotTopK,
  updateChatbotWelcomeMessage,

  updateChatbotAppData,
  resetChatbotAppData,
} = chatbotAppSlice.actions;

export const selectChatbotActiveAppId = (state) =>
  state.chatbotApp.appData.activeAppId;
export const selectChatbotDraftAppData = (state) =>
  state.chatbotApp.appData.draftAppData;
export const selectChatbotModelPrompt = (state) =>
  state.chatbotApp.appData.modelPrompt;
export const selectChatbotSelectedModel = (state) =>
  state.chatbotApp.appData.selectedModel;
export const selectChatbotBackingId = (state) =>
  state.chatbotApp.appData.backingId;
export const selectChatbotBackingIndexTags = (state) =>
  state.chatbotApp.appData.backingIndexTags;
export const selectChatbotBotImageFile = (state) =>
  state.chatbotApp.appData.botImageFile;
export const selectChatbotBotImagePreview = (state) =>
  state.chatbotApp.appData.botImagePreview;
export const selectChatbotBotImageURL = (state) =>
  state.chatbotApp.appData.botImageURL;
export const selectChatbotAppName = (state) => state.chatbotApp.appData.appName;
export const selectChatbotAppDescription = (state) =>
  state.chatbotApp.appData.appDescription;
export const selectChatbotCustomPath = (state) =>
  state.chatbotApp.appData.customPath;
export const selectChatbotAppStatus = (state) =>
  state.chatbotApp.appData.appStatus;
export const selectChatbotAppType = (state) => state.chatbotApp.appData.appType;
export const selectChatbotSelectedDataFiles = (state) =>
  state.chatbotApp.appData.selectedDataFiles;
export const selectChatbotSelectedIndex = (state) =>
  state.chatbotApp.appData.selectedIndex;
export const selectChatbotShowCTA = (state) => state.chatbotApp.appData.showCTA;
export const selectChatbotCtaTitle = (state) =>
  state.chatbotApp.appData.ctaTitle;
export const selectChatbotCtaURL = (state) => state.chatbotApp.appData.ctaURL;
export const selectedChatbotFiles = (state) => state.chatbotApp.appData.files;
export const selectChatbotFuseDocuments = (state) =>
  state.chatbotApp.appData.fuseDocuments;
export const selectChatbotOgImageFile = (state) =>
  state.chatbotApp.appData.ogImageFile;
export const selectChatbotOgImageURL = (state) =>
  state.chatbotApp.appData.ogImageURL;
export const selectChatbotIndexName = (state) =>
  state.chatbotApp.appData.indexName;
export const selectChatbotPromptInputByCreator = (state) =>
  state.chatbotApp.appData.promptInputByCreator;

export const selectChatbotHeaderBackgroundColor = (state) =>
  state.chatbotApp.appData.styles.headerBackgroundColor;
export const selectChatbotHeaderFontColor = (state) =>
  state.chatbotApp.appData.styles.headerFontColor;
export const selectChatbotChatBodyBackgroundColor = (state) =>
  state.chatbotApp.appData.styles.chatBodyBackgroundColor;
export const selectChatbotPageBackgroundColor = (state) =>
  state.chatbotApp.appData.styles.pageBackgroundColor;
// export const selectChatbotHeaderFontSize = (state) =>
//   state.chatbotApp.appData.styles.headerFontSize
// export const selectChatbotHeaderFontFamily = (state) =>
//   state.chatbotApp.appData.styles.headerFontFamily
export const selectChatbotBotMessageBackgroundColor = (state) =>
  state.chatbotApp.appData.styles.botMessageBackgroundColor;
export const selectChatbotBotMessageFontColor = (state) =>
  state.chatbotApp.appData.styles.botMessageFontColor;
export const selectChatbotUserMessageBackgroundColor = (state) =>
  state.chatbotApp.appData.styles.userMessageBackgroundColor;
export const selectChatbotUserMessageFontColor = (state) =>
  state.chatbotApp.appData.styles.userMessageFontColor;
export const selectChatbotTemperature = (state) =>
  state.chatbotApp.appData.temperature;
export const selectChatbotTopK = (state) => state.chatbotApp.appData.topK;
export const selectChatbotWelcomeMessage = (state) =>
  state.chatbotApp.appData.welcomeMessage;

export const selectChatbotPublishingFlag = (state) =>
  state.chatbotApp.meta.isPublishing;
export const selectChatbotSavingFlag = (state) =>
  state.chatbotApp.meta.isSaving;
export const selectChatbotIsLoadingAppData = (state) =>
  state.chatbotApp.meta.isLoadingAppData;

export default chatbotAppSlice.reducer;
