import { PRICE_IDS, STRIPE_PLANS } from '@/constants/enums/stripe';
import { useAppUser } from '@/contexts/AppUserContext';
import { getAccessToken } from '@/helpers/common';
import { Menu, Transition } from '@headlessui/react';
import axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { HiUserCircle } from 'react-icons/hi';
import ProgressBar from '../UsageBar';
import * as EmailValidator from 'email-validator';

const SideNavDropdownForDesktop = ({ userData, setIsOpen }) => {
  const router = useRouter();
  const { appUser, auth0User } = useAppUser();

  const takeUserToStripeDashboard = async () => {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL +
        '/create_customer_portal_session',
      {},
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    window.location.href = response?.data?.url;
  };

  return (
    <div className="cursor-pointer pt-0.5">
      <Menu as="div" className="">
        <div className="justify-between">
          <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none ">
            <span className="sr-only">Open user menu</span>
            <div className="h-10 ml-2 flex items-center space-x-4 focus:outline-none outline-none">
              {auth0User?.picture ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  src={auth0User?.user_metadata?.picture || auth0User?.picture}
                  className="h-8 w-8 rounded-full"
                  alt=""
                />
              ) : (
                <HiUserCircle className="rounded-full w-6 h-6" />
              )}
              <span className="font-normal truncate w-20">
                {EmailValidator.validate(auth0User.name)
                  ? auth0User.nickname
                  : auth0User.name}
              </span>
            </div>
            <div className="flex-row ml-14 py-2 flex items-center ">
              <Image
                priority
                src="/assets/icons/SettingsIcon.svg"
                height={18}
                width={18}
                className="cursor-pointer"
                alt="Settings Icon"
              />
            </div>
            {/*<div className="bg-[#2483E2] h-full w-auto">Test</div>*/}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-8 bottom-20 rounded-lg w-[189px] shadow-[0px-2px-3px-rbga(0,0,0,0.6)] border-[#E9E9E9] border bg-white focus:shadow-md">
            <Menu.Item className="DropdownMenuItem py-3 px-4">
              <ProgressBar userData={appUser} />
            </Menu.Item>

            <Menu.Item className="DropdownMenuItem py-3 px-4 ">
              {appUser?.is_member ? (
                <div
                  className="cursor-pointer text-sm border-t flex justify-between flex-row items-center hover:bg-[#F2F2F2]"
                  onClick={takeUserToStripeDashboard}
                >
                  {/* <img src={PaymentIcon} alt="Payment Icon" /> */}
                  <label className="text-sm cursor-pointer text-[#2483E2]">
                    Pro Plan
                  </label>
                  {/* <label className="text-[#2483E2] cursor-pointer">
                    Stripe Dashboard
                  </label> */}
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src="/assets/icons/ProPlan.svg"
                    className=" w-4 h-4"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  className="bg-[#2483E2] flex flex-row text-sm justify-between border-t items-center hover:bg-[#2483E2]/90 cursor-pointer"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <label className="text-white cursor-pointer">Upgrade</label>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src="/assets/icons/ProIconWhite.svg"
                    className=" w-4 h-4"
                    alt="Pro Icon"
                  />
                </div>
              )}
            </Menu.Item>

            <Menu.Item className="DropdownMenuItem border-y py-3 px-4">
              <div className="flex flex-row items-center text-sm justify-between hover:bg-[#F2F2F2] cursor-pointer">
                {/*{user?.picture ? (
                <img src={user.picture} className="rounded-full w-6 h-6" />
                ) : (
                <HiUserCircle className="rounded-full w-6 h-6" />
                )}*/}
                <label className="font-normal text-gray-500 truncate">
                  {auth0User?.email}
                </label>
                <div className="pl-1 stroke-gray-500 relative w-5 h-5 str">
                  <Image
                    priority
                    src="/assets/icons/EmailIcon2.svg"
                    fill
                    alt="Email Icon"
                  />
                </div>
              </div>
            </Menu.Item>

            <Menu.Item className="DropdownMenuItem py-3 px-4 hover:bg-[#F2F2F2] items-center">
              <div className="border-t">
                <Link
                  href="/api/auth/logout"
                  className="justify-between flex flex-row items-center cursor-pointer"
                >
                  <span className="text-sm text-gray-500">Logout</span>
                  <Image
                    priority
                    src="/assets/icons/Logout.svg"
                    height={0}
                    width={20}
                    className="pl-1"
                    alt=""
                  />
                </Link>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SideNavDropdownForDesktop;
