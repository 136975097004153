import React from 'react';
import * as Progress from '@radix-ui/react-progress';

const UsageBar = ({ userData }) => {
  const [progress, setProgress] = React.useState(13);

  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(66), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="px-4 py-3">
      <div className="flex justify-between">
        <label className="text-sm">Responses Left</label>
        <label className="text-sm">
          {userData?.remaining_app_executions || 'N/A'}
        </label>
      </div>
      {/* <Progress.Root className="ProgressRoot flex flex-row" value={progress}>
      
      <Progress.Indicator
        className="ProgressIndicator"
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root> */}
    </div>
  );
};

export default UsageBar;
