import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from 'next/image';
import SideNavDropdownForDesktop from '../desktop/SideNavDropdowForDesktop';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { HiUserCircle } from 'react-icons/hi';
import UpgradeModal from '../UpgradeModal';
import { useAppUser } from '@/contexts/AppUserContext';

const SideNav = () => {
  const router = useRouter();
  const { appUser: userData, auth0User: user, error, isLoading } = useAppUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  useEffect(() => {
    if (router.query.flow === 'upgrade') {
      setIsPricingModalOpen(true);
    }
  }, [router.query.flow]);

  const isDashboard = () => {
    return router.pathname === '/dashboard';
  };

  const isDataSourcesPage = () => {
    return router.pathname === '/datasources';
  };

  const isCommunityAppsPage = () => {
    return router.pathname === '/featured';
  };

  const isEditorPage = () => {
    return (
      router.pathname === '/editor' ||
      router.pathname === '/editor/[appId]' ||
      router.pathname === '/editor/chat' ||
      router.pathname === '/editor/chat/[appId]' ||
      router.pathname === '/editor/form' ||
      router.pathname === '/editor/form/[appId]'
    );
  };

  const isBusinessPage = () => {
    return router.pathname === '/business';
  };

  const isLandingPage = () => {
    return router.pathname === '/';
  };

  const isAppPage = () => {
    return (
      router.pathname === '/apps/[appId]' ||
      router.pathname === '/embed/[appId]'
    );
  };

  const isAppDetailsPage = () => {
    return router.pathname === '/details/[appId]';
  };

  const isBlogPage = () => {
    return router.pathname === '/blog';
  };

  const isMasterClassPage = () => {
    return router.pathname === '/masterclass';
  };

  if (isLoading) return null;
  if (error) return null;

  if (
    isLandingPage() ||
    isAppPage() ||
    isBlogPage() ||
    isEditorPage() ||
    isBusinessPage() ||
    isMasterClassPage()
  )
    return null;

  return (
    <div>
      <header
        className={`visible py-5 border-r justify-between sticky top-0 flex-col h-screen w-60 bg-white text-black ${
          isEditorPage() ? 'h-14' : 'h-18'
        }`}
      >
        {/* Part 1 - Rubber Logo */}

        <Link href="/dashboard" className="cursor-pointer">
          <Image
            priority
            src="/assets/icons/Logo.svg"
            height={0}
            width={100}
            alt="Rubber Logo"
            className="mx-4"
          />
        </Link>

        {/* Part 2 - My apps + Community Apps switcher */}
        {user &&
          (isDashboard() ||
            isCommunityAppsPage() ||
            isAppDetailsPage() ||
            isDataSourcesPage()) && (
            <div
              className={`border-t hidden lg:flex text-xs flex-row grow mt-4 pt-4`}
            >
              {/* Apps / Community apps switcher */}
              <div className="flex flex-col items-left px-4">
                <Link
                  href="/dashboard"
                  className={`${
                    isDashboard() || isAppDetailsPage()
                      ? 'text-black border-b border-black font-medium bg-gray-200 rounded-lg'
                      : 'text-[#181818] opacity-50'
                  }  text-sm px-3 py-3 w-[13em] cursor-pointer `}
                >
                  My Apps
                </Link>
                <Link
                  href="/datasources"
                  className={`${
                    isDataSourcesPage()
                      ? 'text-black border border-black font-medium bg-gray-200 rounded-lg'
                      : 'text-[#181818] opacity-50'
                  }  text-sm px-3 py-3 w-[13em] cursor-pointer `}
                >
                  Data Sources
                </Link>
                {/*<Link
                  href="/featured"
                  className={`${
                    isCommunityAppsPage()
                      ? 'text-black border border-black font-medium bg-gray-200 rounded-lg'
                      : 'text-[#181818] opacity-50'
                  }  text-sm px-3 py-3 w-[13em] cursor-pointer `}
                >
                  Discover Community Apps
                  Featured
                </Link>*/}
              </div>
            </div>
          )}

        {!user && (isDashboard() || isCommunityAppsPage()) && (
          <div
            className={`border-t hidden lg:flex text-xs flex-row grow mt-4 pt-4`}
          >
            {/* Apps / Community apps switcher */}
            <div className="flex flex-col items-left px-4">
              <Link
                href="/featured"
                className={`${
                  isCommunityAppsPage()
                    ? 'text-black border border-black bg-gray-200 rounded-lg'
                    : 'text-[#181818] opacity-50'
                }  text-sm px-3 py-3 w-[13em] cursor-pointer`}
              >
                {/*Discover Community Apps*/}
                Featured
              </Link>
            </div>
          </div>
        )}

        {/* Part 3 - Account Callout or Login CTA */}
        {!user ? (
          <div className="fixed justify-between bottom-0 w-60 cursor-pointer flex px-2 py-4 space-x-4">
            <Link
              href="/api/auth/login?returnTo=/dashboard"
              className="bg-[#2483E2] text-white text-sm py-2.5 px-6 rounded-lg w-1/2 text-center"
            >
              Login
            </Link>

            <Link
              href="/api/auth/signup?returnTo=/dashboard"
              className="bg-gray-300 text-black text-sm py-2.5 px-6 rounded-lg w-1/2 text-center"
            >
              Signup
            </Link>
          </div>
        ) : (
          !isEditorPage() && (
            <div className="fixed justify-between bottom-0 w-60 cursor-pointer">
              {!userData?.is_member ? (
                <div
                  className="bg-[#2483E2] cursor-pointer text-center h-12 w-auto items-center flex justify-left px-4"
                  onClick={() => {
                    setIsPricingModalOpen(true);
                  }}
                >
                  <Image
                    priority
                    src="/assets/icons/ProIconWhite.svg"
                    height={18}
                    width={18}
                    alt="Pro Subscription Icon"
                  />
                  <label className="cursor-pointer ml-4 text-sm text-white">
                    Upgrade to a Pro Plan
                  </label>
                </div>
              ) : (
                <div className="pr-8 h-full"></div>
              )}
              <div className="pl-2 py-2 flex flex-row items-center border-t">
                <SideNavDropdownForDesktop
                  userData={userData}
                  setIsOpen={setIsPricingModalOpen}
                />
              </div>
              <div className="flex md:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </button>
              </div>
              <Dialog
                as="div"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
              >
                <Dialog.Panel
                  focus="true"
                  className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
                >
                  <div className="flex items-center justify-between">
                    <a href="#" className="-mt-3 ml-2.5">
                      <span className="sr-only">Rubber.ai</span>
                      <div className="h-7">
                        <Image fill src="/assets/icons/Logo.svg" alt="" />
                      </div>
                    </a>
                    <button
                      type="button"
                      className="-mt-2.5 -mr-0.5 rounded-lg p-2.5 text-gray-700"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="divide-y divide-gray-500/10">
                      <div className="flex py-4 items-center space-x-2 focus:outline-none outline-none">
                        {user?.picture ? (
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            src={user?.user_metadata?.picture || user?.picture}
                            className="h-8 w-8 rounded-full"
                            alt="Profile Picture"
                          />
                        ) : (
                          <HiUserCircle className="rounded-full w-6 h-6" />
                        )}
                        <span className="font-normal">{user?.email}</span>
                      </div>
                      {user && (isDashboard() || isCommunityAppsPage()) && (
                        <div className="flex flex-col">
                          <Link
                            onClick={() => setMobileMenuOpen(false)}
                            href="/dashboard"
                            className={`${
                              isDashboard() ? '' : ''
                            } text-[#181818] text-sm pt-6 cursor-pointer`}
                          >
                            My Apps
                          </Link>

                          <Link
                            onClick={() => setMobileMenuOpen(false)}
                            href="/featured"
                            className={`${
                              isCommunityAppsPage() ? '' : ''
                            } text-[#181818] text-sm py-6 cursor-pointer`}
                          >
                            Discover Community Apps
                          </Link>
                        </div>
                      )}
                      <div className="py-4">
                        <Link
                          href="/api/auth/logout"
                          className="flex flex-row items-center gap-2 hover:bg-gray-200"
                        >
                          {/*<Image
                  priority
                  src="/assets/icons/Logout.svg"
                  height={0}
                  width={20}
                  className="pl-1"
              />*/}
                          <span className="text-sm text-black">Logout</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Dialog>
            </div>
          )
        )}
      </header>

      <UpgradeModal
        isOpen={isPricingModalOpen}
        setIsOpen={setIsPricingModalOpen}
        userData={userData}
      />
    </div>
  );
};

export default SideNav;
