import Image from 'next/image';

export const Variable = ({ data = {}, onClick = () => {}, className = '' }) => {
  return (
    <span
      className={`cursor-pointer w-fit text-sm font-medium inline-flex items-center px-3 py-1 rounded-3xl border my-2 mx-2 ${className}`}
      onClick={onClick}
    >
      <p>{data.label}</p>
      <Image
        priority
        src="/assets/icons/AddIcon.svg"
        className="ml-1 text-blue-50"
        height={0}
        width={10}
        alt="Add Variable to prompt icon"
      />
    </span>
  );
};
