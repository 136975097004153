import { ENVIRONMENTS } from './environments'

export const STRIPE_PLANS = {
  FREE: 'free',
  PRO: 'pro',
}

export const PRICE_IDS = {
  [ENVIRONMENTS.LOCAL]: {
    [STRIPE_PLANS.PRO]: 'price_1MGpX0I2leaMmTArUEEsyJjA',
  },
  [ENVIRONMENTS.DEVELOPMENT]: {
    [STRIPE_PLANS.PRO]: 'price_1MGpX0I2leaMmTArUEEsyJjA',
  },
  [ENVIRONMENTS.PRODUCTION]: {
    [STRIPE_PLANS.PRO]: 'price_1Ml4DCI2leaMmTArTSHP0SUe',
  },
}
