import axios from 'axios';

export const getAccessToken = async () => {
  const accessTokenResponse = await axios.get('/api/auth/fetchAuthToken');
  const accessToken = accessTokenResponse.data.accessToken;

  return accessToken;
};

export function getClientReferenceId() {
  return (
    (window?.Rewardful && window?.Rewardful?.referral) ||
    'checkout_' + new Date().getTime()
  );
}
