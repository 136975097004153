import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Editor, Transforms, Range, createEditor, Text } from 'slate';
import { withHistory } from 'slate-history';
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSelected,
  useFocused,
} from 'slate-react';
import { Variable } from '../Variable';

import { Portal } from './components';

const processNode = (node, variablesList, outputVariablesList) => {
  if (Text.isText(node)) {
    return node.text;
  } else if (node.type === 'mention') {
    const selectedVariable =
      variablesList?.find((v) => v.id === node.variable.id) ||
      outputVariablesList?.find((v) => v.id === node.variable.id);

    if (selectedVariable?.category && selectedVariable?.name)
      return `\${${selectedVariable?.category}.${selectedVariable?.name}}`;
    return '';
  }

  const processedPrompt = node.children
    ?.map((n) => processNode(n, variablesList, outputVariablesList))
    .join('');

  return processedPrompt;
};

export const performPromptSerialization = (
  nodes,
  variablesList,
  outputVariablesList
) => {
  return nodes
    ?.map((node) => processNode(node, variablesList, outputVariablesList))
    .join('\n\n');
};

const SlateEditor = ({
  variablesList = [],
  outputVariablesList = [],
  promptText,
  improvedPromptText,
  setPromptText,
  setImprovedPromptText,
  promptIndex = null,
}) => {
  const ref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        variablesList={variablesList}
        outputVariablesList={outputVariablesList}
        promptIndex={promptIndex}
      />
    ),
    [variablesList, outputVariablesList, promptIndex]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );

  useEffect(() => {
    setImprovedPromptText(
      performPromptSerialization(
        JSON.parse(promptText),
        variablesList,
        outputVariablesList
      )
    );
  }, []);

  const getfilteredVariables = () => {
    if (!search) return variablesList;

    const filteredVariableList = variablesList.filter((variable) => {
      return variable.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    });

    return filteredVariableList;
  };

  const vars = getfilteredVariables() || variablesList;

  const onKeyDown = useCallback(
    (event) => {
      if (target && vars.length > 0) {
        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            const prevIndex = index >= vars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          }
          case 'ArrowUp': {
            event.preventDefault();
            const nextIndex = index <= 0 ? vars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          }
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, vars[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [index, search, target]
  );

  // const processNode = (node) => {
  //   if (Text.isText(node)) {
  //     return node.text
  //   } else if (node.type === 'mention') {
  //     const selectedVariable = variablesList.find(
  //       (v) => v.id === node.variable.id
  //     )

  //     return `\${${selectedVariable.category}.${selectedVariable.name}}`
  //   }

  //   const processedPrompt = node.children?.map((n) => processNode(n)).join('')

  //   return processedPrompt
  // }

  useEffect(() => {
    if (target && vars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [vars.length, editor, index, search, target]);

  return (
    <Slate
      editor={editor}
      value={
        promptIndex
          ? JSON.parse(promptText || '[]')
          : JSON.parse(promptText || '')
      }
      onChange={(value) => {
        const { selection, operations, children } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: 'word' });
          const before = wordBefore && Editor.before(editor, wordBefore);
          const beforeRange = before && Editor.range(editor, before, start);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);

          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }

        setTarget(null);

        // Handling the text change
        const isAstChange = operations.some(
          (op) => 'set_selection' !== op.type
        );
        if (isAstChange) {
          const content = JSON.stringify(value);
          if (promptIndex === null) setPromptText(content);
          else {
            setPromptText((prevPromptText) => {
              const newPromptText = [...prevPromptText];
              newPromptText[promptIndex] = {
                ...newPromptText[promptIndex],
                text: content,
              };
              return newPromptText;
            });
          }

          setImprovedPromptText(
            performPromptSerialization(
              children,
              variablesList,
              outputVariablesList
            )
          );
        }
      }}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        placeholder="Enter your prompt"
        className="cursor-pointer text-sm min-h-[128px] p-4 w-full rounded-md border-[#7D8398]/30 bg-white leading-10"
      />

      <div className="my-4 flex flex-row flex-wrap">
        {variablesList?.map((v) => (
          // TODO: Rename this to a better and easy to identify name like Pill or Button. These names are more generaized than the variable.
          <Variable
            data={v}
            onClick={(event) => {
              event.preventDefault();
              // Transforms.select(editor, target)
              insertMention(editor, v);
              // setTarget(null)
            }}
            className="bg-blue-100 text-[#0068C3] hover:bg-[#489CF0]/30 border-blue-400"
            key={v.id}
          />
        ))}

        {outputVariablesList?.map((v, index) => {
          if (index < promptIndex)
            return (
              <Variable
                data={v}
                onClick={(event) => {
                  event.preventDefault();
                  insertMention(editor, v, 'output');
                }}
                key={v.id}
                className="bg-purple-100 text-[#6b00c3] hover:bg-purple-200 border-purple-400"
              />
            );
        })}
      </div>

      {target && vars.length > 0 && (
        <Portal>
          <div
            ref={ref}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 1,

              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 1px 5px rgba(0,0,0,.2)',
            }}
            className="cursor-pointer text-sm px-1 py-1"
            data-cy="mentions-portal"
          >
            {vars?.map((variable, i) => (
              <div
                key={variable.id}
                className="px-2 py-1 hover:bg-[#B4D5FF] bg-transparent rounded"
                onClick={(event) => {
                  event.preventDefault();
                  Transforms.select(editor, target);
                  insertMention(editor, variable);
                }}
              >
                {variable.label}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </Slate>
  );
};

const withMentions = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === 'mention' || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor, variable, category = 'input') => {
  const mention = {
    type: 'mention',
    category: category,
    variable,
    id: variable.id,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = (props) => {
  const { attributes, children, element, outputVariablesList, variablesList } =
    props;

  switch (element.type) {
    case 'mention':
      if (
        outputVariablesList.filter((v) => v.id === element.id).length === 0 &&
        variablesList.filter((v) => v.id === element.id).length === 0
      )
        return;
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Mention = ({
  attributes,
  children,
  element,
  variablesList,
  outputVariablesList,
  promptIndex,
}) => {
  const selected = useSelected();
  const focused = useFocused();

  const selectedVariable =
    variablesList.find(
      (v) => v.id === element.id || v.id === element.variable.id
    ) ||
    outputVariablesList.find(
      (v) => v.id === element.id || v.id === element.variable.id
    );

  const outputVariableIndex = outputVariablesList.findIndex(
    (outputVariable) => outputVariable.id === selectedVariable?.id
  );

  const isAccessible =
    outputVariableIndex >= 0 && outputVariableIndex < promptIndex;
  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  };
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold';
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic';
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${selectedVariable?.name}`}
      // style={style}
      className={`cursor-pointer w-fit text-sm font-medium inline-flex items-center px-2.5 py-1 rounded-3xl border  ${
        element.category === 'output'
          ? isAccessible
            ? 'bg-purple-100 text-[#6b00c3] hover:bg-purple-200 border-purple-400'
            : 'border-red-400 bg-red-100 text-[#c3004b] hover:bg-[#489CF0]/30'
          : 'border-blue-400 bg-blue-100 text-[#0068C3] hover:bg-[#489CF0]/30'
      }`}
    >
      {children} {selectedVariable?.label}
    </span>
  );
};

export default SlateEditor;
