import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from 'next/image';
import ProfileDropdownForDesktop from '../desktop/ProfileDropdownForDesktop';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { HiUserCircle } from 'react-icons/hi';
import UpgradeModal from '../UpgradeModal';
import { useAppUser } from '@/contexts/AppUserContext';

const Header = () => {
  const router = useRouter();
  const { appUser, auth0User } = useAppUser();

  const { auth0User: user, error, isLoading } = useAppUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  useEffect(() => {
    if (router.query?.flow === 'upgrade') {
      setIsPricingModalOpen(true);
    }
  }, [router.query?.flow]);

  const isDashboard = () => {
    return router.pathname === '/dashboard';
  };

  const isBusinessPage = () => {
    return router.pathname === '/business';
  };

  const isCommunityAppsPage = () => {
    return router.pathname === '/featured';
  };

  const isEditorPage = () => {
    return (
      router.pathname === '/editor' ||
      router.pathname === '/editor/[appId]' ||
      router.pathname === '/editor/chat' ||
      router.pathname === '/editor/chat/[appId]' ||
      router.pathname === '/editor/form' ||
      router.pathname === '/editor/form/[appId]'
    );
  };

  const isMasterClassPage = () => {
    return router.pathname === '/masterclass';
  };

  const isLandingPage = () => {
    return router.pathname === '/';
  };

  const isAppPage = () => {
    return (
      router.pathname === '/apps/[appId]' ||
      router.pathname === '/embed/[appId]'
    );
  };

  const isBlogPage = () => {
    return router.pathname === '/blog';
  };

  if (isLoading) return null;
  if (error) return null;

  if (
    isLandingPage() ||
    isAppPage() ||
    isBlogPage() ||
    isEditorPage() ||
    isBusinessPage() ||
    isMasterClassPage()
  )
    return null;

  return (
    <div>
      <header
        className={`flex flex-row w-full bg-white items-center text-black justify-between pl-8 border-b ${
          isEditorPage() ? 'h-14' : 'h-18'
        }`}
      >
        {/*Part 1 - Rubber Logo or back to Apps link */}
        <Link href="/dashboard" className="cursor-pointer">
          <Image
            priority
            src="/assets/icons/Logo.svg"
            height={0}
            width={120}
            alt="Rubber Logo"
          />
        </Link>

        {/* Part 2 - My apps + Community Apps switcher 
        {user && (isDashboard() || isCommunityAppsPage()) && (
          <div
            className={`hidden lg:flex text-xs flex-row space-x-4 items-center grow justify-end`}
          >
            {/* Apps / Community apps switcher 
            <div className="flex flex-row space-x-2 items-center">
              <Link
                href="/dashboard"
                className={`${
                  isDashboard()
                    ? 'text-black border-b border-black'
                    : 'text-[#181818] opacity-50'
                }  text-sm py-3 px-6 cursor-pointer`}
              >
                My Apps
              </Link>

              <Link
                href="/featured"
                className={`${
                  isCommunityAppsPage()
                    ? 'text-black border border-black'
                    : 'text-[#181818] opacity-50'
                }  text-sm py-3 px-6 cursor-pointer`}
              >
                Discover Community Apps
              </Link>
            </div>
          </div>
        )}*/}

        {/* Part 3 - Account Callout or Login CTA */}
        {!user ? (
          <Link
            href="/api/auth/login?returnTo=/dashboard"
            className="bg-[#2483E2] text-white text-sm py-2.5 px-6 rounded-lg mr-8"
          >
            Login
          </Link>
        ) : (
          !isEditorPage() && (
            <div className="border-l h-full flex items-center pl-6 mx-6">
              <div className="hidden lg:flex flex-row items-center space-x-2">
                <ProfileDropdownForDesktop
                  userData={appUser}
                  setIsOpen={setIsPricingModalOpen}
                />
                {!appUser?.is_member ? (
                  <div
                    className="bg-[#2483E2] cursor-pointer text-center h-18 w-16 items-center flex justify-center"
                    onClick={() => {
                      setIsPricingModalOpen(true);
                    }}
                  >
                    <Image
                      priority
                      src="/assets/icons/ProIconWhite.svg"
                      height={20}
                      width={20}
                      alt="Pro Subscription Icon"
                    />
                  </div>
                ) : (
                  <div className="pr-8 h-full"></div>
                )}
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </button>
              </div>
              <Dialog
                as="div"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
              >
                <Dialog.Panel
                  focus="true"
                  className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
                >
                  <div className="flex items-center justify-between">
                    <a href="#" className="-mt-3 ml-2.5">
                      <span className="sr-only">Rubber.ai</span>
                      <Image
                        priority
                        src="/assets/icons/Logo.svg"
                        height={0}
                        width={120}
                        alt="Rubber Logo"
                      />
                    </a>
                    <button
                      type="button"
                      className="-mt-2.5 -mr-2.5 rounded-lg p-2.5 text-gray-700"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="divide-y divide-gray-500/10">
                      <div className="flex py-4 items-center space-x-2 focus:outline-none outline-none">
                        {auth0User?.picture ? (
                          <div className="h-8 w-8 rounded-full relative">
                            <Image
                              src={auth0User?.picture}
                              fill
                              alt="User Profile Picture"
                            />
                          </div>
                        ) : (
                          <HiUserCircle className="rounded-full w-6 h-6" />
                        )}

                        <span className="font-normal">{auth0User.email}</span>
                      </div>
                      {auth0User &&
                        (isDashboard() || isCommunityAppsPage()) && (
                          <div className="flex flex-col">
                            <Link
                              onClick={() => setMobileMenuOpen(false)}
                              href="/dashboard"
                              className={`${
                                isDashboard() ? '' : ''
                              } text-[#181818] text-sm pt-6 cursor-pointer`}
                            >
                              My Apps
                            </Link>

                            <Link
                              onClick={() => setMobileMenuOpen(false)}
                              href="/featured"
                              className={`${
                                isCommunityAppsPage() ? '' : ''
                              } text-[#181818] text-sm py-6 cursor-pointer`}
                            >
                              Featured
                            </Link>
                          </div>
                        )}
                      <div className="py-4">
                        <Link
                          href="/api/auth/logout"
                          className="flex flex-row items-center gap-2 hover:bg-gray-200"
                        >
                          {/*<Image
                  priority
                  src="/assets/icons/Logout.svg"
                  height={0}
                  width={20}
                  className="pl-1"
              />*/}
                          <span className="text-sm text-black">Logout</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Dialog>
            </div>
          )
        )}
      </header>

      <UpgradeModal
        isOpen={isPricingModalOpen}
        setIsOpen={setIsPricingModalOpen}
        userData={appUser}
      />
    </div>
  );
};

export default Header;
