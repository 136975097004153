import { configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import chatbotAppReducer from './slices/chatbotApp/chatbotAppSlice';
import commonDataReducer from './slices/commonDataSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    chatbotApp: chatbotAppReducer,
    commonData: commonDataReducer,
  },
});
