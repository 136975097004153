import { performPromptSerialization } from '@/components/editorOld/PromptComponents/SlateEditor';

import {
  transformer,
  getVariablesListForPrompt,
} from '@/utils/craftTransformer';
import { generateBackendCompatibleSchema } from '@/utils/schemaHelpers';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { getAccessToken } from './common';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import { SiOpenai } from 'react-icons/si';
import { FcGoogle } from 'react-icons/fc';
// A constant to be used where ever a new prompt step is added
export const defaultPromptStepObject = {
  text: JSON.stringify([
    {
      type: 'paragraph',
      children: [
        {
          text: ' ',
        },
      ],
    },
  ]),
  model: 'gpt-3.5-turbo',
  backing_index_id: null,
  model_config: {},
  backing_index_config: {},
};

// Current setup of available models. We will be upgrading this further when the time comes!
export const availableModels = {
  'models/chat-bison-001': {
    name: 'Google PaLM 2',
    id: 'models/chat-bison-001',
    description: `Google's LLM model, trained on 540 billion parameters. It is the largest language model ever trained, and is capable of generating long, coherent, and diverse text. It is also capable of answering questions, and performing other tasks.`,
    max_tokens: 4000,
    paid: false,
    is_chat_enabled: true,
    icon: <FcGoogle className="w-5 h-5" />,
  },
  // 'models/chat-unicorn-001': {
  //   name: 'Unicorn - Google PaLM 2',
  //   id: 'models/chat-unicorn-001',
  //   description: `Google's LLM model, trained on 540 billion parameters. It is the largest language model ever trained, and is capable of generating long, coherent, and diverse text. It is also capable of answering questions, and performing other tasks.`,
  //   max_tokens: 4000,
  //   paid: false,
  //   is_chat_enabled: true,
  //   icon: <FcGoogle className="w-5 h-5" />,
  // },
  'gpt-3.5-turbo': {
    name: 'GPT 3.5',
    id: 'gpt-3.5-turbo',
    description:
      'Most capable GPT-3.5 model and optimized for chat at 1/10th the cost of text-davinci-003. Will be updated with OpenAIs latest model iteration.',
    max_tokens: 4000,
    paid: false,
    is_chat_enabled: true,
    icon: <SiOpenai className="w-4 h-4 mr-1" />,
  },
  'gpt-4': {
    name: 'GPT 4',
    id: 'gpt-4',
    description:
      'More capable than any GPT-3.5 model, able to do more complex tasks, and optimized for chat. Will be updated with our latest model iteration.',
    max_tokens: 4000,
    paid: true,
    is_chat_enabled: false,
    icon: <SiOpenai className="w-4 h-4" />,
  },
};

// This helper method will extract the variables from the current craft.js schema
export const initializeVariables = (rawJson) => {
  const transformedJson = transformer(JSON.parse(rawJson), 'ROOT');

  if (transformedJson.children.length > 1)
    return getVariablesListForPrompt(transformedJson);
  else return getVariablesListForPrompt(transformedJson.children[0]);
};

// This helper method will extract the output variables from the current prompts Objects
export const fetchOutputVariables = (promptsObject) => {
  const identifiedOutputVariables = promptsObject?.map((prompt, index) => {
    const outputVariableObject = prompt.output;
    const obj = {
      id: outputVariableObject.name,
      label: `${outputVariableObject.label} ${index + 1}`,
      name: outputVariableObject.name,
      category: 'required_variables',
    };

    return obj;
  });
  return identifiedOutputVariables;
};

export const hasOutputVariables = (outputText, outputVariables) => {
  try {
    const anyOutputVariableUsed = outputVariables?.map(
      (variable) => outputText.search(variable.id) >= 0
    );
    return anyOutputVariableUsed.some((flag) => flag);
  } catch (err) {
    return false;
  }
};

// Helper method to upload data files to our BE and return the IDs
export const uploadDataFiles = async (files = []) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const newFile = new File(
        [files[i]],
        `${files[i].name.replaceAll(' ', '_')}`
      );
      formData.append('file', newFile);
    }

    const documentUploadResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/upload_documents`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    return documentUploadResponse.data;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'Error uploading data files (editorHelpers.js/uploadDataFiles)',
      level: 'error',
      extra: {
        ...err,
      },
    });
    return {};
  }
};

// Helper method to create an index for an app and return the id
export const initiateModelIndex = async (indexName = '') => {
  try {
    const createIndexResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/initialize_index`,
      {
        index_name: indexName ? indexName + '_' + nanoid(8) : nanoid(8),
      },
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    return createIndexResponse.data;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    return {};
  }
};

// Helper method to extend an index with documents and return the response
export const extendModelIndex = async (
  indexId,
  documentIds = [],
  async = true
) => {
  try {
    const createIndexResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/extend_index`,
      {
        index_id: indexId,
        document_ids: documentIds,
        is_async: true,
      },
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    return createIndexResponse.data;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    return {};
  }
};

export const shrinkModelIndex = async ({
  indexId,
  documentIds = [],
  clearAll = false,
}) => {
  try {
    const shrinkIndexResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/shrink_index`,
      {
        index_id: indexId,
        document_ids: documentIds,
        clear_all: clearAll,
      },
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    return shrinkIndexResponse.data;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    return {};
  }
};

export const updateIndexName = async ({ indexId, newName }) => {
  try {
    const updateIndexNameResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/update_index_name`,
      {
        index_id: indexId,
        new_name: newName,
      },
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    return updateIndexNameResponse.data;
  } catch (err) {
    // TODO: Add Better Sentry Logging
    return {};
  }
};

export const createModelIndex = async (documentIds = [], indexName = '') => {
  try {
    const indexData = await initiateModelIndex(indexName);
    const indexId = indexData.index.id;
    const extendIndexResponse = await extendModelIndex(indexId, documentIds);
    return extendIndexResponse;
    // const createIndexResponse = await axios.post(
    //   `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/extend_index`,
    //   {
    //     index_id: indexId,
    //     document_ids: documentIds,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${await getAccessToken()}`,
    //     },
    //   }
    // );

    // return createIndexResponse.data;
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    return {};
  }
};
// export const createModelIndex = async (documentIds = [], indexName = '') => {
//   try {
//     const indexData = await initiateModelIndex(indexName);
//     const indexId = indexData.index.id;
//     const createIndexResponse = await axios.post(
//       `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/extend_index`,
//       {
//         index_id: indexId,
//         document_ids: documentIds,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${await getAccessToken()}`,
//         },
//       }
//     );
//     return createIndexResponse.data;
//   } catch (err) {
//         // Add Better Sentry Logging
// Sentry.captureEvent({
//   message: 'An error occurred',
//   level: 'error',
//   extra: {
//     ...err
//   },
// });
//     return {};
//   }
// };

// Helper method to create the prompt text for a single step.
export const createPromptText = (promptText, variablesList) => {
  let text = null;
  try {
    text = performPromptSerialization(
      JSON.parse(promptText),
      variablesList
    ).trim();
  } catch (err) {
    text = performPromptSerialization(promptText, variablesList).trim();
  }

  if (!text) return null;
  return text + '\n';
};

export const createMultiStepPromptText = (
  prompt,
  variablesList,
  identifiedOutputVariables
) => {
  const text = performPromptSerialization(
    JSON.parse(prompt.text),
    variablesList,
    identifiedOutputVariables
  ).trim();

  if (!text) return null;
  return text + '\n';
};

{
  /*export const createMultiStepOutputText = (
  prompt,
  variablesList,
  identifiedOutputVariables
) => {
  const text = performOutputSerialization(
    JSON.parse(prompt.text),
    variablesList,
    identifiedOutputVariables
  ).trim()

  if (!text) return null
  return text + '\n'
}*/
}

// Helper method to get the available documents from the BE
export const fetchAvailableDocuments = async () => {
  const availableDocumentsResponse = await axios.get(
    `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/documents`,
    {
      params: {
        limit: 1000,
        order_dir: 'desc',
        order_by: 'created_at',
      },
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return availableDocumentsResponse.data.data;
};

// Helper method to fetch the available Indexes from the BE
export const fetchAvailableIndexes = async () => {
  const availableIndexesResponse = await axios.get(
    `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/indexes`,
    {
      params: {
        limit: 1000,
        order_dir: 'desc',
        order_by: 'created_at',
      },
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return availableIndexesResponse.data.data;
};

export const fetchUserApps = async (user_id) => {
  const availableAppsResponse = await axios.get(
    `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/authorized_schemas`,
    {
      params: {
        user_id: user_id,
        limit: 1000,
        order_dir: 'desc',
        order_by: 'created_at',
      },
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );

  return availableAppsResponse.data.data;
};

const createSingleStepWorkflowSchema = async (
  promptText,
  variablesList,
  selectedModel,
  files,
  selectedDataFiles,
  selectedIndex,
  backingId,
  setBackingId
) => {
  let newBackingId = null;
  if (!backingId) {
    if (selectedIndex) {
      setBackingId(selectedIndex.id);
      newBackingId = selectedIndex.id;
    } else if ((files && files?.length > 0) || selectedDataFiles.length > 0) {
      toast.success('Creating Datasource', {
        className: 'toast-message-success',
      });

      let newDocumentsIds = [];
      let reusedDocumentsIds = [];

      if (files && files?.length > 0) {
        const uploadDataFilesResponse = await uploadDataFiles(files);
        newDocumentsIds = uploadDataFilesResponse?.successful_uploads?.map(
          (doc) => doc.id
        );
      }
      if (selectedDataFiles.length > 0) {
        reusedDocumentsIds = selectedDataFiles?.map(
          (existingDocument) => existingDocument.id
        );
      }

      toast.success('Creating Index for your prompts!', {
        className: 'toast-message-success',
      });
      const createIndexResponse = await createModelIndex([
        ...reusedDocumentsIds,
        ...newDocumentsIds,
      ]);

      if (createIndexResponse.index.id) {
        setBackingId(createIndexResponse.index.id);
        newBackingId = createIndexResponse.index.id;
      }
    }
  }

  return {
    type: 'sequential',
    nodes: [
      {
        type: 'transformer',
        save_output: 'output',
        input: createPromptText(promptText, variablesList),
        transformer: selectedModel,
        backing_index_id: backingId || newBackingId,
        transformer_args: {
          temperature: 0.5,
        },
      },
    ],
  };
};

const indexCreationHelper = async (prompt, backingId) => {
  try {
    if (backingId) return backingId;
    else {
      if (prompt.backing_index_config.selectedIndex) {
        return prompt.backing_index_config.selectedIndex.id;
      } else if (
        (prompt.backing_index_config.files &&
          prompt.backing_index_config.files?.length > 0) ||
        prompt.backing_index_config.selectedDataFiles?.length > 0
      ) {
        toast.success('Creating Datasource', {
          className: 'toast-message-success',
        });

        let newDocumentsIds = [];
        let reusedDocumentsIds = [];

        if (
          prompt.backing_index_config.files &&
          prompt.backing_index_config.files?.length > 0
        ) {
          const uploadDataFilesResponse = await uploadDataFiles(
            prompt.backing_index_config.files
          );
          newDocumentsIds = uploadDataFilesResponse?.successful_uploads?.map(
            (doc) => doc.id
          );
        }
        if (prompt.backing_index_config.selectedDataFiles.length > 0) {
          reusedDocumentsIds =
            prompt.backing_index_config.selectedDataFiles?.map(
              (existingDocument) => existingDocument.id
            );
        }

        const createIndexResponse = await createModelIndex(
          [...reusedDocumentsIds, ...newDocumentsIds],
          prompt.backing_index_config.indexName
        );

        if (createIndexResponse.index.id) {
          return createIndexResponse.index.id;
        }
      }

      return null;
    }
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
  }
};

const createMultiStepWorkflowSchema = async (promptText, variablesList) => {
  const identifiedOutputVariables = fetchOutputVariables(promptText);
  const res = await Promise.all(
    promptText?.map(async (prompt) => {
      let newBackingId = await indexCreationHelper(
        prompt,
        prompt.backing_index_id
      );

      return {
        type: 'transformer',
        save_output: prompt.output.name,
        input: createMultiStepPromptText(
          prompt,
          variablesList,
          identifiedOutputVariables
        ),
        transformer: prompt.model,
        backing_index_id: newBackingId,
        transformer_args: {
          temperature: prompt.model_config?.temperature || 0.5,
        },
        similarity_top_k: parseInt(prompt.model_config?.topK || 0.5, 10),
      };
    })
  );

  return res;
};

export const validateNewIndexNames = ({ promptObject }) => {
  const promptSteps = [];
  promptObject?.map((promptStep, index) => {
    if (
      (promptStep?.backing_index_config?.files?.length > 0 ||
        promptStep?.backing_index_config?.selectedDataFiles?.length > 0) &&
      !promptStep?.backing_index_config?.indexName
    )
      promptSteps.push(index + 1);
  });
  return promptSteps;
};

export const checkForDuplicateFiles = ({
  availableDataFiles,
  promptObject,
  userId,
}) => {
  const allPromptsFiles = [];
  const duplicateFiles = [];
  for (let i = 0; i < promptObject?.length || 0; i++) {
    const prompt = promptObject[i];
    if (prompt.backing_index_config.files?.length > 0) {
      prompt.backing_index_config.files.map((file) => {
        allPromptsFiles.push({
          step: i + 1,
          name: `${userId}_${file.name.replaceAll(' ', '_')}`,
          originalName: file.name,
        });
      });
    }
  }

  for (let i = 0; i < availableDataFiles?.length || 0; i++) {
    const availableFile = availableDataFiles[i];
    for (let j = 0; j < allPromptsFiles.length; j++) {
      const promptFile = allPromptsFiles[j];
      if (availableFile.name === promptFile.name) {
        duplicateFiles.push({
          step: promptFile.step,
          name: promptFile.originalName,
        });
      }
    }
  }

  return duplicateFiles;
};

// Helper method to save the current app as a Draft
export const saveApp = async ({
  serializedAppSchema,
  appName,
  appDescription,
  customPath,
  showcase,
  appStatus,
  copyable,
  promptText,
  setPromptText,
  isMultistepPrompt,
  backingId,
  selectedIndex,
  selectedDataFiles,
  files,
  activeAppId,
  setActiveAppId,
  setBackingId,
  setIsOpen,
  setIsSaving,
  selectedModel,
  outputObject,
  showModal = true,
  password,
  passwordProtection,
  hasPasswordChanged,
}) => {
  try {
    setIsSaving(true);
    const variablesList = initializeVariables(serializedAppSchema);
    const appSchema = transformer(JSON.parse(serializedAppSchema), 'ROOT');
    const identifiedOutputVariables = isMultistepPrompt
      ? fetchOutputVariables(promptText)
      : [];
    const configSchema =
      appSchema.children.length > 1
        ? generateBackendCompatibleSchema(appSchema, identifiedOutputVariables)
        : generateBackendCompatibleSchema(
            appSchema.children[0],
            identifiedOutputVariables
          );

    let updatedPromptText = null;
    if (!isMultistepPrompt)
      configSchema.schema.model = await createSingleStepWorkflowSchema(
        promptText,
        variablesList,
        selectedModel,
        files,
        selectedDataFiles,
        selectedIndex,
        backingId,
        setBackingId
      );
    else {
      const preparedNodes = await createMultiStepWorkflowSchema(
        promptText,
        variablesList
      );

      configSchema.schema.model = {
        type: 'sequential',
        nodes: preparedNodes,
      };

      updatedPromptText = promptText?.map((prompt, index) => {
        const { files, ...reducedBackingIdConfig } =
          prompt.backing_index_config;
        prompt.backing_index_config = { ...reducedBackingIdConfig, files: [] };
        prompt.backing_index_id = preparedNodes[index].backing_index_id;
        return prompt;
      });

      setPromptText(updatedPromptText);
    }

    const saveableSchema = {
      title: appName,
      description: appDescription,
      custom_url: customPath,
      showcase: showcase,
      frontend_schema: {
        rendererCompatibleSchema: appSchema,
        craftjsSchema: serializedAppSchema,
        useNewRenderer: true,
        promptText: updatedPromptText || promptText,
        isMultistepPrompt: isMultistepPrompt,
        // outputText: createMultiStepOutputText(
        outputText: createMultiStepPromptText(
          { text: outputObject },
          variablesList,
          identifiedOutputVariables
        ),
        outputObject: outputObject,
        version: 1,
      },
      config_schema: configSchema,
      config_schema_publishing_state: appStatus,
      copyable: copyable,
      executable: appStatus === 'DRAFT' ? true : true,
      password_protection: passwordProtection,
      // password: passwordProtection ? password : null,
    };

    if (saveableSchema) {
      if (activeAppId) saveableSchema['id'] = parseInt(activeAppId);
      if (hasPasswordChanged) saveableSchema['password'] = password;

      const URL = activeAppId
        ? '/api/creator/updateSchema'
        : '/api/creator/saveSchema';
      const response = await axios.post(URL, saveableSchema);

      if (response.status === 200) {
        const message =
          appStatus === 'DRAFT'
            ? 'Your app has been saved.'
            : 'Changes have been published';
        if (showModal) {
          toast.success(message, {
            className: 'toast-message-success',
          });
          setIsOpen(appStatus === 'DRAFT' ? false : true);
        }
        setActiveAppId(response.data.schema.id);
      }
    } else {
      if (showModal)
        toast.error(
          'App cannot be published at the moment! Please try again later!'
        );
    }
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
  } finally {
    setIsSaving(false);
  }
};

export const publishApp = async ({
  appId = null,
  serializedAppSchema,
  appName,
  appDescription,
  customPath,
  showcase,
  appStatus,
  copyable,
  promptText,
  isMultistepPrompt,
  backingId,
  selectedIndex,
  selectedDataFiles,
  files,
  setBackingId,
  setIsOpen,
  setLoading,
  setAppStatus,
  setActiveAppId,
  setPromptText,
  selectedModel,
  outputObject,
  passwordProtection,
  password,
  hasPasswordChanged,
}) => {
  try {
    setLoading(true);
    const saveableSchema = await verifySchema(
      true,
      serializedAppSchema,
      appName,
      appDescription,
      customPath,
      showcase,
      copyable,
      promptText,
      isMultistepPrompt,
      backingId,
      setBackingId,
      setPromptText,
      selectedModel,
      files,
      selectedDataFiles,
      selectedIndex,
      outputObject,
      password,
      passwordProtection,
      hasPasswordChanged
    );
    if (saveableSchema) {
      // If we have recieved an appID in the args, then we are updating the app as part of the create app flow itself.
      // Like, the user has started creating a new app, saved in between and continued editing
      if (appId) {
        saveableSchema['id'] = parseInt(appId);
      }

      const URL = appId
        ? '/api/creator/updateSchema'
        : '/api/creator/saveSchema';
      const response = await axios.post(URL, saveableSchema);

      if (response.status === 200) {
        toast.success('Your app has been published.', {
          className: 'toast-message-success',
        });
        setIsOpen(true);
        setAppStatus(response.data.schema.publishing_state);
        setActiveAppId(response.data.schema.id);
      }
    } else {
      // toast.error(
      //   'App cannot be published at the moment! Please try again later!'
      // )
    }
  } catch (err) {
    // TODO: Have block level try-catching. JSON parsing block. BE Schema generation block. FE Schema validation block!
    const errorMessage =
      err?.response?.data?.error ||
      'We experienced an issue publishing your app. Please review and try again.';
    if (err?.response && err?.response?.status !== 200)
      toast.error(errorMessage, {
        className: 'toast-message-error',
      });
    else
      toast.error(errorMessage, {
        className: 'toast-message-error',
      });
  } finally {
    setLoading(false);
  }
};

export const updateApp = async ({
  serializedAppSchema,
  appName,
  appDescription,
  customPath,
  showcase,
  copyable,
  promptText,
  isMultistepPrompt,
  backingId,
  selectedIndex,
  selectedDataFiles,
  files,
  activeAppId,
  setBackingId,
  setIsOpen,
  setLoading,
  setAppStatus,
  setActiveAppId,
  setPromptText,
  selectedModel,
  outputObject,
  password,
  passwordProtection,
  hasPasswordChanged,
}) => {
  try {
    setLoading(true);
    const saveableSchema = await verifySchema(
      false,
      serializedAppSchema,
      appName,
      appDescription,
      customPath,
      showcase,
      copyable,
      promptText,
      isMultistepPrompt,
      backingId,
      setBackingId,
      setPromptText,
      selectedModel,
      files,
      selectedDataFiles,
      selectedIndex,
      outputObject,
      password,
      passwordProtection,
      hasPasswordChanged
    );
    if (saveableSchema) {
      const response = await axios.post('/api/creator/updateSchema', {
        ...saveableSchema,
        id: parseInt(activeAppId),
      });

      // toast.success('Your app has been published.', {
      //   className: 'toast-message-success',
      // })
      setIsOpen(true);
      setAppStatus(response.data.schema.publishing_state);
      setActiveAppId(response.data.schema.id);
    } else {
      // toast.error(
      //   'App cannot be published at the moment! Please try again later!'
      // )
    }
  } catch (err) {
    // TODO: Have block level try-catching. JSON parsing block. BE Schema generation block. FE Schema validation block!
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    const errorMessage =
      err?.response?.data?.error ||
      'There was an error publishing your app. Please try again.';
    if (err.response && err.response.status !== 200)
      toast.error(errorMessage, {
        className: 'toast-message-error',
      });
    else
      toast.error(errorMessage, {
        className: 'toast-message-error',
      });
  } finally {
    setLoading(false);
  }
};

const verifySchema = async (
  showValideSchemaToast = true,
  serializedAppSchema,
  appName,
  appDescription,
  customPath,
  showcase,
  copyable,
  promptText,
  isMultistepPrompt,
  backingId,
  setBackingId,
  setPromptText,
  selectedModel,
  files,
  selectedDataFiles,
  selectedIndex,
  outputObject,
  password,
  passwordProtection,
  hasPasswordChanged
) => {
  try {
    const variablesList = initializeVariables(serializedAppSchema);
    const identifiedOutputVariables = isMultistepPrompt
      ? fetchOutputVariables(promptText)
      : [];

    const appSchema = transformer(JSON.parse(serializedAppSchema), 'ROOT');

    const configSchema =
      appSchema.children.length > 1
        ? generateBackendCompatibleSchema(appSchema, identifiedOutputVariables)
        : generateBackendCompatibleSchema(
            appSchema.children[0],
            identifiedOutputVariables
          );

    let updatedPromptText = null;
    if (!isMultistepPrompt)
      configSchema.schema.model = await createSingleStepWorkflowSchema(
        promptText,
        variablesList,
        selectedModel,
        files,
        selectedDataFiles,
        selectedIndex,
        backingId,
        setBackingId
      );
    else {
      const preparedNodes = await createMultiStepWorkflowSchema(
        promptText,
        variablesList
      );

      configSchema.schema.model = {
        type: 'sequential',
        nodes: preparedNodes,
      };

      updatedPromptText = promptText?.map((prompt, index) => {
        const { files, ...reducedBackingIdConfig } =
          prompt.backing_index_config;
        prompt.backing_index_config = { ...reducedBackingIdConfig, files: [] };
        prompt.backing_index_id = preparedNodes[index].backing_index_id;
        return prompt;
      });

      setPromptText(updatedPromptText);
    }

    const saveableSchema = {
      title: appName,
      description: appDescription,
      custom_url: customPath,
      showcase: showcase,
      frontend_schema: {
        rendererCompatibleSchema: appSchema,
        craftjsSchema: serializedAppSchema,
        useNewRenderer: true,
        promptText: updatedPromptText || promptText,
        isMultistepPrompt: isMultistepPrompt,
        outputText: createMultiStepPromptText(
          { text: outputObject },
          variablesList,
          identifiedOutputVariables
        ),
        outputObject: outputObject,
        version: 1,
      },
      config_schema: configSchema,
      config_schema_publishing_state: 'PUBLISHED',
      copyable: copyable,
      executable: true,
      password_protected: passwordProtection,
      // password: passwordProtection ? password : null,
    };

    if (saveableSchema) {
      if (hasPasswordChanged) saveableSchema['password'] = password;
    }
    // Checking for the required fields
    if (
      saveableSchema.frontend_schema.outputText &&
      !hasOutputVariables(
        saveableSchema.frontend_schema.outputText,
        identifiedOutputVariables
      )
    ) {
      toast.error(
        'Please add an output to your response in the Workflow step.',
        {
          className: 'toast-message-error',
        }
      );
      return;
    }
    if (!configSchema?.schema?.model) {
      toast.error('Please add an AI model to proceed.', {
        className: 'toast-message-error',
      });
      return;
    }
    if (!configSchema?.schema?.model?.nodes?.[0]?.transformer) {
      toast.error('Please add an AI model to proceed.', {
        className: 'toast-message-error',
      });
      return;
    }
    if (!configSchema?.schema?.model?.nodes?.[0]?.input) {
      toast.error('Please add a prompt to proceed.', {
        className: 'toast-message-error',
      });
      return;
    }
    if (!appName) {
      toast.error('Plase add a name for your app.', {
        className: 'toast-message-error',
      });
      return;
    }

    const response = await axios.post(
      '/api/creator/validateSchema',
      saveableSchema
    );

    if (showValideSchemaToast)
      toast.success('All the necessary info is provided.', {
        className: 'toast-message-success',
      });
    return saveableSchema;
    // Setting up the preview of app!
    // const rawJson = serializedAppSchema
    // const transformedJson = transformer(JSON.parse(rawJson), 'ROOT')
    // setContent(JSON.stringify(transformedJson))
    // try {
    //   const userProvidedSchema = transformedJson.children

    //   const processedData = getInputsFromJSON(userProvidedSchema)
    //   const { initialValues, inputs, validationSchema } = processedData

    //   setInitialValues(initialValues)
    //   setInputs(inputs)
    //   setValidationSchema(validationSchema)
    //   setIsValidApp(true)
    //   return saveableSchema
    // } catch (err) {
    //   // TODO: Have block level try-catching. JSON parsing block. BE Schema generation block. FE Schema validation block!

    // Add Better Sentry Logging
    // Sentry.captureEvent({
    //   message: 'An error occurred',
    //   level: 'error',
    //   extra: {
    //     ...err
    //   },
    // });
    //   if (err.response && err.response.status !== 200)
    //     toast.error(err.response.data.error, {
    //       className: 'toast-message-error',
    //     })
    //   else
    //     toast.error(
    //       'We experienced an issue publishing your app. Please review and try again.',
    //       {
    //         className: 'toast-message-error',
    //       }
    //     )
    //   return null
    // }

    //   router.push('/')
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message: 'An error occurred',
      level: 'error',
      extra: {
        ...err,
      },
    });
    // TODO: Have block level try-catching. JSON parsing block. BE Schema generation block. FE Schema validation block!
    if (err.response && err.response.status !== 200)
      toast.error(err?.response?.data?.error, {
        className: 'toast-message-error',
      });
    else {
      toast.error(
        'We experienced an issue publishing your app. Please review and try again.',
        {
          className: 'toast-message-error',
        }
      );
    }
  }
};

export const validateSchema = async ({
  serializedAppSchema,
  promptText,
  isMultistepPrompt,
  backingId,
  setBackingId,
  setPromptText,
  selectedModel,
  files,
  selectedDataFiles,
  selectedIndex,
  outputObject,
}) => {
  const errors = [];
  try {
    const variablesList = initializeVariables(serializedAppSchema);
    const identifiedOutputVariables = isMultistepPrompt
      ? fetchOutputVariables(promptText)
      : [];

    const appSchema = transformer(JSON.parse(serializedAppSchema), 'ROOT');

    const configSchema =
      appSchema.children.length > 1
        ? generateBackendCompatibleSchema(appSchema, identifiedOutputVariables)
        : generateBackendCompatibleSchema(
            appSchema.children[0],
            identifiedOutputVariables
          );

    let updatedPromptText = null;
    if (!isMultistepPrompt)
      configSchema.schema.model = await createSingleStepWorkflowSchema(
        promptText,
        variablesList,
        selectedModel,
        files,
        selectedDataFiles,
        selectedIndex,
        backingId,
        setBackingId
      );
    else {
      const preparedNodes = await createMultiStepWorkflowSchema(
        promptText,
        variablesList
      );

      configSchema.schema.model = {
        type: 'sequential',
        nodes: preparedNodes,
      };

      updatedPromptText = promptText?.map((prompt, index) => {
        const { files, ...reducedBackingIdConfig } =
          prompt.backing_index_config;
        prompt.backing_index_config = { ...reducedBackingIdConfig, files: [] };
        prompt.backing_index_id = preparedNodes[index].backing_index_id;
        return prompt;
      });

      // setPromptText(updatedPromptText);
    }

    // const saveableSchema = {
    //   frontend_schema: {
    //     rendererCompatibleSchema: appSchema,
    //     craftjsSchema: serializedAppSchema,
    //     useNewRenderer: true,
    //     promptText: updatedPromptText || promptText,
    //     isMultistepPrompt: isMultistepPrompt,
    //     outputText: createMultiStepPromptText(
    //       { text: outputObject },
    //       variablesList,
    //       identifiedOutputVariables
    //     ),
    //     outputObject: outputObject,
    //     version: 1,
    //   },
    //   config_schema: configSchema,
    // };

    // Checking for the required fields
    if (!configSchema?.schema?.model) {
      errors.push({
        type: 'missing_model',
        message: 'Please select an AI model to test your app.',
      });
    }
    for (let i = 0; i < configSchema?.schema?.model?.nodes?.length; i++) {
      const node = configSchema?.schema?.model?.nodes?.[i];
      if (!node?.transformer) {
        errors.push({
          type: 'missing_model',
          message: `Please add an AI model to Step-${i + 1} in workflow step`,
        });
      }
      if (!node?.input) {
        errors.push({
          type: 'missing_prompt',
          message: `Please add an input variable to Prompt ${
            i + 1
          } of the Workflow step`,
        });
      }
    }

    return errors;
  } catch (err) {
    // TODO: Add Better Sentry Logging
    // TODO: Have block level try-catching. JSON parsing block. BE Schema generation block. FE Schema validation block!
    if (err.response && err.response.status !== 200)
      toast.error(err?.response?.data?.error, {
        className: 'toast-message-error',
      });
    else {
      toast.error(
        'We experienced an issue publishing your app. Please review and try again.',
        {
          className: 'toast-message-error',
        }
      );
    }
  }
};

export const handleElementCopy = ({ query, nodeId, add }) => {
  const serializedNode = query.node(nodeId).toSerializedNode();
  const parsedNode = query.parseSerializedNode(serializedNode).toNode();
  const newId = nanoid(10);
  const parentNodeId = JSON.parse(query.serialize())['ROOT'].nodes[0];
  const insertionPosition = JSON.parse(query.serialize())[
    parentNodeId
  ].nodes.findIndex((id) => id === nodeId);
  parsedNode.id = newId;

  const node = query.parseFreshNode(parsedNode).toNode();
  add(node, parentNodeId, insertionPosition + 1);
};
