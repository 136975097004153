import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import {
  fetchAvailableDocuments,
  fetchAvailableIndexes,
  defaultPromptStepObject,
} from '@/helpers/editorHelpers';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

export const fetchAllData = createAsyncThunk('app/fetchAllData', async () => {
  const availableDataFiles = await fetchAvailableDocuments();
  const availableIndexes = await fetchAvailableIndexes();

  return {
    availableDataFiles,
    availableIndexes,
  };
});

export const fetchAppDataById = createAsyncThunk(
  'app/fetchAppDataById',
  async ({ appId, userId }) => {
    try {
      const response = await axios.get(
        `/api/creator/fetchAppData?app_id=${appId}&user_id=${userId}`
      );

      if (response.status === 200) {
        return response.data.data[0];
      }
      throw new Error('Error fetching app data');
    } catch (err) {
      // Add Better Sentry Logging
      Sentry.captureEvent({
        message: 'An error occurred',
        level: 'error',
        extra: {
          ...err,
        },
      });
    }
  }
);

const initialState = {
  draftAppData: null,
  modelPrompt: '',
  selectedModel: 'gpt-3.5-turbo',
  backingId: null,
  promptText: [
    {
      ...defaultPromptStepObject,
      id: nanoid(10),
      output: {
        name: `output-${nanoid(3)}`,
        label: `Output`,
      },
    },
  ],
  isMultistepPrompt: true,
  outputObject: JSON.stringify([
    {
      type: 'paragraph',
      children: [
        {
          text: ' ',
        },
      ],
    },
  ]),
  appName: '',
  appDescription: '',
  customPath: '',
  showcase: false,
  copyable: false,
  activeAppId: null,
  updateFlow: false,
  appStatus: 'DRAFT',
  dataFiles: [],
  indexes: [],
  selectedIndex: null,
  selectedDataFiles: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateAppName: (state, action) => {
      state.appName = action.payload;
    },
    updateAppDescription: (state, action) => {
      state.appDescription = action.payload;
    },
    copyAppData: (state) => {
      const appData = JSON.parse(window.localStorage.getItem('appData'));
      state.draftAppData = appData;

      state.isMultiStep =
        appData?.['frontend_schema']?.isMultistepPrompt || false;

      let initialPromptObject = [];
      if (state.isMultiStep)
        initialPromptObject = [
          {
            ...defaultPromptStepObject,
            id: nanoid(10),
            output: {
              name: `output-${nanoid(3)}`,
              label: `Output`,
            },
          },
        ];
      else
        initialPromptObject = JSON.stringify([
          {
            type: 'paragraph',
            children: [
              {
                text: ' ',
              },
            ],
          },
        ]);

      state.promptText =
        appData?.['frontend_schema']?.promptText || initialPromptObject;
      if (appData?.['frontend_schema']?.outputObject)
        state.outputObject = appData?.['frontend_schema']?.outputObject;

      state.selectedModel =
        appData?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer;
      state.modelPrompt = appData?.workflow_schema?.schema?.model;
      state.appName = appData?.title;
      state.appDescription = appData?.description;
      state.customPath = appData?.custom_path;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.dataFiles = action.payload.availableDataFiles;
        state.indexes = action.payload.availableIndexes;
      })
      .addCase(fetchAppDataById.pending, (state) => {
        state.isLoadingAppData = true;
      })
      .addCase(fetchAppDataById.rejected, (state) => {
        state.isLoadingAppData = false;
      })
      .addCase(fetchAppDataById.fulfilled, (state, action) => {
        const app = action.payload;
        state.appData = app;
        state.draftAppData = app.frontend_schema.craftjsSchema;

        state.isMultistepPrompt =
          app?.frontend_schema?.isMultistepPrompt || false;

        let initialPromptObject = [];
        if (state.isMultistepPrompt)
          initialPromptObject = [
            {
              ...defaultPromptStepObject,
              id: nanoid(10),
              output: {
                name: `output-${nanoid(3)}`,
                label: `Output`,
              },
            },
          ];
        else
          initialPromptObject = JSON.stringify([
            {
              type: 'paragraph',
              children: [
                {
                  text: ' ',
                },
              ],
            },
          ]);
        state.promptText =
          app?.frontend_schema?.promptText || initialPromptObject;

        if (app['frontend_schema']?.outputObject) {
          state.outputObject = app.frontend_schema?.outputObject;
        }

        state.selectedModel =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.transformer;

        state.modelPrompt = app?.workflow_schema?.schema?.model;
        state.backingId =
          app?.workflow_schema?.schema?.model?.nodes?.[0]?.backing_index_id;

        state.appName = app?.title;
        state.appDescription = app?.description;
        state.customPath = app?.custom_path;
        state.showcase = app.showcase;
        state.appStatus = app.publishing_state;
        state.isLoadingAppData = false;
      });
  },
});

export const { copyAppData, updateAppName, updateAppDescription } =
  appSlice.actions;

export const selectAppName = (state) => state.app.appName;
export const selectAppDescription = (state) => state.app.appDescription;

export default appSlice.reducer;
