import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import SideNav from './SideNav';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchAllData } from '@/redux/slices/commonDataSlice';
import { useAppUser } from '@/contexts/AppUserContext';

const Layout = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { appUser, auth0User: user } = useAppUser();
  const [intercomHash, setIntercomHash] = useState(null);

  useEffect(() => {
    const calculateUserIntercomHash = async () => {
      if (user?.email) {
        const userHashResponse = await axios.get(
          `/api/auth/fetchIntercomHash?email=${user?.email}`
        );
        setIntercomHash(userHashResponse.data);
      }
    };

    calculateUserIntercomHash();
  }, [user?.email]);

  useEffect(() => {
    if (
      router.pathname === '/apps/[appId]' ||
      router.pathname === '/embed/[appId]' ||
      router.pathname === '/masterclass'
    ) {
      if (window?.Intercom) {
        window?.Intercom(
          'shutdown'
          // , {
          //   hide_default_launcher: true,
          // }
        );
      }
    } else {
      if (window?.Intercom && user?.email && intercomHash) {
        window?.Intercom('update', {
          app_id: 'wym4303y',
          name: user?.nickname,
          email: user?.email,
          user_hash: intercomHash,
        });
      }
    }
  }, [router?.pathname, user, intercomHash]);

  useEffect(() => {
    if (!appUser?.id) return;
    dispatch(fetchAllData(appUser?.id));
  }, [dispatch, appUser?.id]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/icon.svg" />

        {/* Primary Meta Tags */}
        <title>Rubber | Build No-Code AI Apps</title>
        <meta name="title" content="Rubber | Build No-Code AI Apps" />
        <meta
          name="description"
          content="Rubber is a powerful no-code platform for creating
          AI-enabled applications with no engineers."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.rubber.ai" />
        <meta property="og:title" content="Rubber | Build No-Code AI Apps" />
        <meta
          property="og:description"
          content="Rubber is a powerful no-code platform for creating
          AI-enabled applications with no engineers."
        />
        <meta
          name="image"
          property="og:image"
          content="/assets/images/visual.png"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.rubber.ai" />
        <meta
          property="twitter:title"
          content="Rubber | Build No-Code AI Apps"
        />
        <meta
          property="twitter:description"
          content="Rubber is a powerful no-code platform for creating
          AI-enabled applications with no engineers."
        />
        <meta property="twitter:image" content="/assets/images/visual.png" />
      </Head>
      <div className="hidden md:visible md:flex flex-row w-full min-w-screen h-full min-h-screen">
        {/* TODO: Improve this conditional rendering of header  */}
        {/*<Header />*/}
        <SideNav />
        <main className="flex grow h-full">{props.children}</main>
        <ToastContainer
          position="bottom-right"
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
      <div className="visible md:hidden flex flex-col w-full min-w-screen h-full min-h-screen">
        {/* TODO: Improve this conditional rendering of header  */}
        {/*<Header />*/}
        <Header />
        <main className="flex grow h-full">{props.children}</main>
        <ToastContainer
          position="bottom-right"
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    </>
  );
};

export default Layout;
