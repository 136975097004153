import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import Image from 'next/image';
import Link from 'next/link';
import { PRICE_IDS, STRIPE_PLANS } from '@/constants/enums/stripe';
import axios from 'axios';
import { getAccessToken, getClientReferenceId } from '@/helpers/common';

const PlanFeatureItem = ({ entry }) => {
  return (
    <p className="flex flex-row py-1 text-body-color text-base leading-2 space-x-2">
      {/*<CheckIcon className="mr-1 h-[20px] w-[20px] text-blue-500" />*/}
      <span className="relative h-10 w-10 lg:h-6 lg:w-6 px-2 mr-2">
        <Image src="/assets/icons/CheckIcon.svg" alt="Check Icon" fill={true} />
      </span>
      {entry}
    </p>
  );
};

const PlanCard = ({ children }) => {
  return (
    <div className="col-span-6 lg:col-span-2 flex flex-col h-full ">
      <div className="flex flex-col border-primary h-full rounded-3xl shadow-pricing relative z-10 border border-opacity-20 py-10 px-8 lg:py-10 lg:px-6 xl:p-12 ">
        {children}
      </div>
    </div>
  );
};

const UpgradeModal = ({ isOpen, setIsOpen, userData }) => {
  const takeUserToStripe = async () => {
    const payload = {
      price_ids: [
        PRICE_IDS[process.env.NEXT_PUBLIC_ENVIRONMENT][STRIPE_PLANS.PRO],
      ],
      client_reference_id: getClientReferenceId(),
    };

    const response = await axios.post(
      process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL + '/create_checkout_session',
      payload,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    window.location.href = response?.data?.url;
  };

  const takeUserToStripeDashboard = async () => {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL +
        '/create_customer_portal_session',
      {},
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    window.location.href = response?.data?.url;
  };

  return (
    <Dialog.Root
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      <Dialog.Portal>
        <Dialog.Overlay
          className="DialogOverlay"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <Dialog.Content className="DialogContent w-full h-full px-6 py-4 md:w-9/12 overflow-scroll">
          <div className="flex flex-row items-center justify-between">
            <Dialog.Title className="font-semibold text-lg pb-4">
              Upgrade Your Plan
            </Dialog.Title>
            <Dialog.Close asChild>
              <button
                className="-mt-4 cursor-pointer"
                aria-label="Close"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </div>

          <Dialog.Description className="DialogDescription grid grid-cols-6 gap-6 h-full pb-14">
            <PlanCard>
              <div className="flex flex-col grow">
                <span className="flex flew-row text-primary mb-4 text-[#2D3C73] lg:text-lg font-semibold">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/SmileFaceIcon.svg"
                    alt=""
                  />
                  Personal
                </span>
                <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                  Perfect for personal or non-commercial uses.
                </p>
                <h2 className="text-dark py-4 text-3xl lg:text-[42px] font-medium">
                  $0
                  <span className="text-body-color text-base font-medium text-gray-600">
                    {' '}
                    / month
                  </span>
                </h2>

                <div className="text-gray-600 pt-4 pb-9">
                  <PlanFeatureItem entry="Unlimited apps" available={true} />
                  <PlanFeatureItem entry="50 entries/month" available={true} />
                  <PlanFeatureItem
                    entry="1 custom data source"
                    available={false}
                  />
                  <PlanFeatureItem
                    entry="Embed apps anywhere"
                    available={false}
                  />
                </div>
              </div>

              <Link href="">
                <button
                  className={`mt-4 w-full text-center h-12 rounded-lg ${
                    userData?.is_member
                      ? 'cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] text-white'
                      : 'cursor-not-allowed hover:bg-gray-700 bg-gray-500 text-white'
                  }`}
                  onClick={takeUserToStripeDashboard}
                >
                  {userData?.is_member ? 'Downgrade to Free' : 'Current Plan'}
                </button>
              </Link>
            </PlanCard>

            <PlanCard>
              <div className="flex flex-col grow">
                <span className="flex flex-row text-primary mb-4 text-blue-500 text-lg font-semibold">
                  <img
                    className="h-5 mt-0.5 mr-2"
                    src="/assets/icons/ProPlan.svg"
                    alt=""
                  />
                  Pro
                </span>
                <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                  Perfect for client projects or small businesses.
                </p>
                <h2 className="text-dark py-4 text-3xl lg:text-[42px] font-medium">
                  $30
                  <span className="text-body-color text-base font-medium text-gray-600">
                    {' '}
                    / month
                  </span>
                </h2>

                <div className="text-gray-600 pt-4">
                  <PlanFeatureItem entry="Unlimited apps" available={true} />
                  <PlanFeatureItem
                    entry="Unlimited GPT-4 access"
                    available={true}
                  />
                  <PlanFeatureItem
                    entry="Unlimited data sources"
                    available={true}
                  />
                  <PlanFeatureItem
                    entry="1000 entries/month"
                    available={true}
                  />
                  <PlanFeatureItem
                    entry="Remove Rubber branding"
                    available={true}
                  />
                  {/*<PlanFeatureItem
                  entry="Password-Protected apps"
                  available={true}
                />*/}
                </div>
              </div>

              <button
                className={`mt-4 w-full text-center h-12 rounded-lg ${
                  !userData?.is_member
                    ? 'cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] text-white'
                    : 'cursor-default hover:bg-gray-700 bg-gray-500 text-white'
                }`}
                // className="cursor-pointer hover:bg-[#489CF0] shadow-inner bg-[#2483E2] mt-4 w-full text-white text-center h-12 rounded-lg"
                onClick={() =>
                  userData?.is_member
                    ? takeUserToStripeDashboard()
                    : takeUserToStripe()
                }
              >
                {userData?.is_member ? 'Active Plan' : 'Purchase'}
              </button>
            </PlanCard>

            <PlanCard>
              <div className="flex flex-col grow">
                <span className="text-primary mb-4 flex flex-row text-[#E29624] text-lg font-semibold">
                  <img
                    className="h-6 mt-0.5 mr-2"
                    src="/assets/icons/ProYellowIcon.svg"
                    alt=""
                  />
                  Partner
                </span>
                <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                  None of the plans fits your need? We still have you covered.
                </p>
                <h2 className="pb-2 text-3xl lg:text-[42px] font-medium">
                  Custom
                </h2>

                <div className="text-gray-600 pt-4">
                  <PlanFeatureItem
                    entry="Fine-tuned AI models"
                    available={true}
                  />
                  <PlanFeatureItem
                    entry="Custom data integrations"
                    available={true}
                  />
                  <PlanFeatureItem
                    entry="Custom volume pricing"
                    available={true}
                  />
                  <PlanFeatureItem entry="Dedicated hosting" available={true} />
                </div>
              </div>

              <Link href="mailto:derek@carbon.ai">
                <button className="cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] mt-4 w-full text-white text-center h-12 rounded-lg">
                  Contact Us
                </button>
              </Link>
            </PlanCard>
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default UpgradeModal;
